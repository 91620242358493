
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { required } from "@/helpers/rules";

@Component
export default class CustomerTypeDialog extends Vue {
  @Prop({ required: true }) private show: boolean;
  @Prop({ required: true }) private title: string;
  @Prop({ required: true }) private label: string;
  @Prop({ required: true }) private itemType: string;

  private required = required;

  @Ref("customerForm") readonly itemForm: HTMLFormElement;

  private isValid = false;

  private isPrivateText = "private";
  private showIsPrivateError = false;
  private customerTypeDialogProperties = {
    name: "",
    itemType: "",
    isPrivate: true,
  };

  mounted(): void {
    this.$set(this.customerTypeDialogProperties, "itemType", this.itemType);
  }

  private changeIsPrivate() {
    if (this.isPrivateText == "company") {
      this.$set(this.customerTypeDialogProperties, "isPrivate", false);
    } else {
      this.$set(this.customerTypeDialogProperties, "isPrivate", true);
    }
  }

  private closeCustomerTypeDialog(): void {
    this.$emit("closeCustomerTypeDialog", this.customerTypeDialogProperties);
  }

  private saveCustomerType(): void {
    this.isValid = this.itemForm.validate();
    if (this.isValid) {
      this.$emit("saveCustomerType", this.customerTypeDialogProperties);
    }
  }
}
